@charset "utf-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  border: 0;
  outline: 0;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  text-decoration: none;
  color: #000;
  background-color: #ff9; }

/* change colours to suit your needs */
mark {
  font-weight: bold;
  font-style: italic;
  color: #000;
  background-color: #ff9; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #cecece; }

input,
select {
  vertical-align: middle; }

ul,
ol {
  list-style-type: none; }

input[type='submit'],
input[type='button'] {
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration {
  display: none; }

input[type='submit']::focus,
input[type='button']::focus {
  outline-offset: -2px; }

//reset

//ベースフォントサイズ
$baseFontSize: 18;
//スマホPSDの横幅
$SmartphoneSize: 750;

//ベース
$widthBase: 1200px;

//メインコンテンツ
$widthMain: 800px;

//サイドナビ
$widthSide: 360px;

//ベースフォント
$fontBase: 'Noto Serif JP', serif ,"ヒラギノ明朝 ProN W6","ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro," メイリオ", Meiryo, Osaka," ＭＳ Ｐゴシック", MS PGothic," sans-serif";

//サブフォント
$subFont: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//rem() ※px指定
@function rem($pixels,$context:$baseFontSize)
{
  @return $pixels / $context * 1rem;
}

//vw()
@function vw($size) {
  @return (($size / $SmartphoneSize) * 100) + vw;
}

//@include liBottom();
@mixin liBottom($px:10,$borderColor:#000)
{
  border-bottom: 1px solid $borderColor;
  margin-bottom: $px + px;
  padding-bottom: $px + px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw($px * 1.5);
    padding-bottom: vw($px * 1.5);
  }
}

//@include link-color();
@mixin link-color($color:#371b4d) {
  color: $color;

  &:hover, &:active, &:focus {
      color: lighten($color, 20%);
    }
}





//setting ----------------------------------------------------

body {
  font-size: $baseFontSize + px;
  @media only screen and (max-width:1080px){
    font-size: vw($baseFontSize * 1.8);
  }

  line-height: 2;
  letter-spacing: 3px;
  @media only screen and (max-width:1080px){
    letter-spacing: vw(3);
  }
  margin: 0;
  padding: 0;
  font-family: $fontBase;
  color: #371b4d;
}

img {
  border: none;
}

.clear {
  clear: both;
  height: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

ul, ol {
  list-style: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  text-align: center;
}

.pccenter{
  text-align: center;
  @media only screen and (max-width:$SmartphoneSize + px){
    text-align: left;
  }
}

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.pc {
  display: block;
  @media only screen and (max-width:1080px){
    display: none;
  }
}
.sp {
  display: none;
  @media only screen and (max-width:1080px){
    display: block;
  }
}
.mt5 {
  margin-top: 5px;
  @media only screen and (max-width:1080px){
    margin-top: vw(5);
  }
}
.mb5 {
  margin-bottom: 5px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(5);
  }
}
.ml5 {
  margin-left: 5px;
  @media only screen and (max-width:1080px){
    margin-left: vw(5);
  }
}
.mr5 {
  margin-right: 5px;
  @media only screen and (max-width:1080px){
    margin-right: vw(5);
  }
}
.mt10 {
  margin-top: 10px;
  @media only screen and (max-width:1080px){
    margin-top: vw(10);
  }
}
.mb10 {
  margin-bottom: 10px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(10);
  }
}
.ml10 {
  margin-left: 10px;
  @media only screen and (max-width:1080px){
    margin-left: vw(10);
  }
}
.mr10 {
  margin-right: 10px;
  @media only screen and (max-width:1080px){
    margin-right: vw(10);
  }
}
.mt15 {
  margin-top: 15px;
  @media only screen and (max-width:1080px){
    margin-top: vw(15);
  }
}
.mb15 {
  margin-bottom: 15px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(15);
  }
}
.ml15 {
  margin-left: 15px;
  @media only screen and (max-width:1080px){
    margin-left: vw(15);
  }
}
.mr15 {
  margin-right: 15px;
  @media only screen and (max-width:1080px){
    margin-right: vw(15);
  }
}
.mt20 {
  margin-top: 20px;
  @media only screen and (max-width:1080px){
    margin-top: vw(20);
  }
}
.mb20 {
  margin-bottom: 20px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(20);
  }
}
.ml20 {
  margin-left: 20px;
  @media only screen and (max-width:1080px){
    margin-left: vw(20);
  }
}
.mr20 {
  margin-right: 20px;
  @media only screen and (max-width:1080px){
    margin-right: vw(20);
  }
}
.mt30 {
  margin-top: 30px;
  @media only screen and (max-width:1080px){
    margin-top: vw(30);
  }
}
.mb30 {
  margin-bottom: 30px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(60);
  }
}
.ml30 {
  margin-left: 30px;
  @media only screen and (max-width:1080px){
    margin-left: vw(30);
  }
}
.mr30 {
  margin-right: 30px;
  @media only screen and (max-width:1080px){
    margin-right: vw(30);
  }
}
.mt40 {
  margin-top: 40px;
  @media only screen and (max-width:1080px){
    margin-top: vw(40);
  }
}
.mb40 {
  margin-bottom: 40px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(80);
  }
}
.ml40 {
  margin-left: 40px;
  @media only screen and (max-width:1080px){
    margin-left: vw(40);
  }
}
.mr40 {
  margin-right: 40px;
  @media only screen and (max-width:1080px){
    margin-right: vw(80);
  }
}
.mb50 {
  margin-bottom: 50px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(100);
  }
}
.mb70 {
  margin-bottom: 70px;
  @media only screen and (max-width:1080px){
    margin-bottom: vw(140);
  }
}


a:hover img {
  opacity: 0.6;
  filter: alpha(opacity=60);
  -ms-filter: "alpha( opacity=60 )";
  background: none !important;
}

a{
  @include link-color();
  text-decoration: none;
}

img{
  max-width: 100%;
}

.inner{
  max-width: 1080px;
  margin: 0 auto;
  @media only screen and (max-width:1080px){
    width:100%;
    padding:0 vw(60);
    box-sizing: border-box;
  }
}

header{
  width: 100%;
  position: fixed;
  z-index: 10000;
    nav{
      transition: 1.5s;
      line-height: 1.3;
      .navinner{
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-align-items: stretch;
        -ms-align-items: stretch;
        align-items: stretch;
      ul{
          padding: 35px 50px;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          -ms-justify-content: flex-start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap:wrap;
          flex-wrap:wrap;
          -webkit-align-items: center;
          -ms-align-items: center;
          align-items: center;
        li{
          margin-left: 25px;
          letter-spacing: 4px;
          font-size: rem(18);
          &:nth-child(2){
            display: none;
          }
          img{
            height: 36px;
            transition: 1.5s;
            &:last-child{
              opacity: 0;
              width: 0;
            }
          }
          a{
            color: #fff;
          }
        }
      }
      .shop{
        transition: 1.5s;
        width: 255px;
        a,span{
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            -ms-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap:wrap;
            flex-wrap:wrap;
            -webkit-align-items: center;
            -ms-align-items: center;
            align-items: center;
            height: 100%;
            color: #fff;
            letter-spacing: 6px;
            font-size: rem(18);
        }
        span{
          color: #bbbbbb;
        }
      }
    }

    ///////// tab sp
    @media only screen and (max-width:1240px){
      background: #f9f6f7;
      width: 100%;
      height: 100vh;
      padding: 0 20vw;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      transition: .5s;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
      display: none;
      &.open{
        opacity: 1;
        z-index: 100000;
      }
      .navwrap{
        height: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
      }
      .navinner{
        ul{
          display: block;
          padding: 0;
          li{
            margin-bottom: 5vh;
            width: 100%;
            margin-left: 0;
            &:nth-child(1){
              display: none;
            }
            &:nth-child(2){
              display: block;
            }
            a{
              color: #371b4d;
              font-size: rem(30);
              @media only screen and (max-width:$SmartphoneSize + px){
                font-size: vw(32);
              }
            }
          }
        }
        .shop{
          width: 100%;
          a,span{
            display: block;
            height: 100%;
            color: #371b4d;
            letter-spacing: 6px;
            font-size: rem(30);
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size: vw(32);
            }
          }
          span{
            color: #d3d3d3;
          }
        }
      }
    }
  }
  &.on{
    nav{
      background: #fff;
      .navinner{
        ul{
          li{
            a{
              color: #371b4d;
            }
            img{
              &:first-child{
                opacity: 0;
                width: 0;
              }
              &:last-child{
                opacity: 1;
                width: auto;
              }
            }
          }
        }
        .shop{
          background: #371b4d;
          &.close{
            background: #d3d3d3;
          }
          @media only screen and (max-width:1240px){
            background: none;
          }
        }
      }
    }
  }

  ///////// メニューボタン
  #sp_menu{
    display: none;
  }
  @media only screen and (max-width:1240px){
    #sp_menu{
      line-height: 1.3;
      position: fixed;
      z-index: 1000000;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
      width: 100%;
      padding: 40px 50px 40px 30px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding:vw(40) vw(50) vw(40) vw(30);
      }
      box-sizing: border-box;
      p{
        &.logo{
          img{
            height: 36px;
            transition: .2s;
            @media only screen and (max-width:$SmartphoneSize + px){
              height:vw(40);
            }
            &:last-child{
              opacity: 0;
              width: 0;
            }
          }
        }
        &#menu{
          #menu_open{
            display: none;
          }
          svg{
            fill:#fff;
            @media only screen and (max-width:$SmartphoneSize + px){
              &#menu_close{
                width:vw(45);
                height:vw(22);
              }
            }
          }
        }
      }
      &.open{
        p{
          &.logo{
            img{
              height: 36px;
              transition: 1.5s;
              @media only screen and (max-width:$SmartphoneSize + px){
                height:vw(40);
              }
              &:first-child{
                opacity: 0;
                width: 0;
                height: 0;
              }
              &:last-child{
                opacity: 1;
                width: auto;
              }
            }
          }
        }
        #menu{
          #menu_open{
            display: block;
            width: 41px;
            height: 26px;
            @media only screen and (max-width:$SmartphoneSize + px){
              width:vw(41);
              height:vw(26);
            }
          }
          svg{
            fill:#371b4d;
            &#menu_close{
              display: none;
            }
          }
        }
      }
    }
    &.on{
      #sp_menu{
        background: #fff;
        transition: 1.5s;
        p{
          &.logo{
            img{
              &:first-child{
                opacity: 0;
                width: 0;
              }
              &:last-child{
                opacity: 1;
                width: auto;
              }
            }
          }
          &#menu{
            svg{
              fill:#371b4d;
            }
          }
        }
      }
    }
  }


}



		.mainvisual {
			position: relative;
			overflow: hidden;
			width: 100%;
			min-height: 100vh;
			background-color: #999;
		}
		.mainvisual-inner-content {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
      width: 100%;
      min-height: 100vh;
      position: relative;
      p{
        position: absolute;
        bottom: 60px;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: rem(12);
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(24);
          bottom: 3vh;
        }
      }
      img{
        width: 70px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(100);
        }
      }
		}



.hero{
  .wrap{
    position: relative;
    height: 600px;
    @media only screen and (max-width:1080px){
      height:vw(595);
    }
    h1{
      position: absolute;
      width: 100%;
      height: 100%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
      writing-mode: vertical-rl;
      letter-spacing: 6px;
      color: #fff;
      font-weight: 300;
      font-size: rem(36);
      @media only screen and (max-width:1080px){
        font-size: vw(48);
      }
    }
    img{
      height: 600px;
      width: 100%;
      object-fit: cover;
      @media only screen and (max-width:1080px){
        height:vw(595);
      }
    }
  }
  .page_title{
    height: 270px;
    @media only screen and (max-width:1080px){
      height: vw(280);
      padding: 0 vw(60);
    }
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    h1{
      font-size: rem(36);
      font-weight: 300;
      @media only screen and (max-width:1080px){
        font-size: vw(48);
      }
    }
  }
}

main{

  @media only screen and (max-width:$SmartphoneSize + px){
    h2{
      &.mb40{
        margin-bottom: vw(40);
      }
    }
  }

  .hide_elem{
    opacity: 0;
    transition: 2s;
    &.show{
      opacity: 1;
    }
  }


  min-height: 20vh;
  h1,h2{
    font-size: rem(36);
    line-height: 1.6;
    font-weight: 300;
    @media only screen and (max-width:1080px){
      font-size: vw(48);
    }
  }
  .bg{
    background: url(../images/bg.png) repeat left top #f0eae8;
    @media only screen and (max-width:1080px){
      background-size:contain;
    }
  }
  .base{
    padding: 140px 0;
    @media only screen and (max-width:1080px){
      padding:vw(140) 0;
    }
  }
  .btn{
    a,span{
      padding: 20px 55px;
      font-size: rem(16);
      text-align: center;
      @media only screen and (max-width:1080px){
        padding:vw(25) 0;
        width:100%;
        font-size: vw(32);
      }
      border: 1px solid #371b4d;
      color: #371b4d;
      transition: .5s;
      display: inline-block;
      &:hover{
        background: #371b4d;
        color: #fff;
      }
    }
    span{
      border: 1px solid #d3d3d3;
      color: #bbb;
      &:hover{
        background: #d3d3d3;
        color: #bbb;
      }
    }
  }
  .small{
    font-size: rem(16);
    @media only screen and (max-width:1080px){
      font-size: vw(32);
    }
  }
  .ja{
    writing-mode: vertical-rl;
    line-height: 1.6;
  }
}


.tradition{
  @media only screen and (max-width:1080px){
    .base{
      padding-top: 0;
      .inner{
        padding: 0;
      }
    }
  }
  .wrap{
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      -ms-align-items: stretch;
      align-items: stretch;
      h2{
        width: 100px;
      }
      .txt{
        width: 400px;
        padding: 100px 40px 0 50px;
        box-sizing: border-box;
      }
      .catch{
        width: 580px;
      }
      @media only screen and (max-width:1080px){
        h2{
          width: 100%;
          writing-mode: unset;
          -webkit-box-ordinal-group:2;
          -ms-flex-order:2;
          -webkit-order:2;
          order:2;
          padding: vw(100) vw(40) vw(60) vw(40);
          text-align: center;
        }
        .txt{
          width: 100%;
          padding: 0 vw(60);
          -webkit-box-ordinal-group:3;
          -ms-flex-order:3;
          -webkit-order:3;
          order:3;
          br{
            display: none;
          }
        }
        .catch{
          width: 100%;
          -webkit-box-ordinal-group:1;
          -ms-flex-order:1;
          -webkit-order:1;
          order:1;
          img{
            width: 100%;
          }
        }
      }
    }
}

#online{
  height: 565px;
  position: relative;
  @media only screen and (max-width:1080px){
    height:auto;
    background: url(../images/bg.png) repeat left top;
  }
  &:after{
    content: '';
    display: block;
    width: 87.375%;
    height: 485px;
    background: url(../images/bg.png) repeat left top;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    @media only screen and (max-width:1080px){
      display: none;
    }
  }
  .inner{
    height: 485px;
    position: relative;
    z-index: 2;
    .catch{
      position: absolute;
      left: 0;
      top: 50px;
    }
    .content{
      padding-top: 80px;
      width: 400px;
      position: absolute;
      right: 0;
      top: 65px;
    }
    @media only screen and (max-width:1080px){
      height:auto;
      padding: 0;
      .catch{
        position: unset;
        img{
          width: 100%;
        }
      }
      .content{
        position: unset;
        width: 100%;
        padding: vw(100) vw(60) vw(190) vw(60);
        box-sizing: border-box;
        h2{
          text-align: center;
        }
        p{
          br{
            display: none;
          }
        }
      }
    }
  }
}

#news_list{
  padding: 120px 0 240px 0;
  @media only screen and (max-width:1080px){
    padding: vw(140) 0 vw(200) 0;
  }
  .inner{
    	display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      -ms-align-items: stretch;
      align-items: stretch;
      h2{
        width: 150px;
        text-align: right;
        font-size: rem(28);
        @media only screen and (max-width:1080px){
          width: 100%;
          text-align: center;
          font-size: vw(40);
          margin-bottom: vw(80);
        }
      }
      .list{
        width: 800px;
        margin-right: 70px;
        font-size: rem(16);
        @media only screen and (max-width:1080px){
          width: 100%;
          margin-right: 0;
          font-size: vw(32);
        }
        .more{
          text-align: right;
          a{
            color: #371b4d;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            -ms-justify-content: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap:wrap;
            flex-wrap:wrap;
            -webkit-align-items: center;
            -ms-align-items: center;
            align-items: center;
            &:after{
              width: 30px;
              height: 7px;
              display: inline-block;
              content: '';
              background: url(../images/more.svg) no-repeat left top;
              margin-left: 18px;
              @media only screen and (max-width:1080px){
                width: vw(50);
                height: vw(10);
                margin-left: vw(20);
                background-size: cover;
              }
            }
          }
        }
        ul{
          li{
            padding: 0 20px;
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #371b4d;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            -ms-justify-content: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap:wrap;
            flex-wrap:wrap;
            -webkit-align-items: stretch;
            -ms-align-items: stretch;
            align-items: stretch;
            span{
              width: 130px;
            }
            a{
              color: #371b4d;
              width: 630px;
              text-decoration: underline;
            }
            @media only screen and (max-width:1080px){
              padding: 0 vw(20);
              padding-bottom: vw(50);
              margin-bottom: vw(50);
              span{
                width: 100%;
              }
              a{
                width: 100%;
              }
            }
          }
        }
      }
  }
}

////////////////////////////////////////////ページ
.breadcrumbs{
  ul{
    width: 1080px;
    margin: 0 auto;
    @media only screen and (max-width:1080px){
      width: 100%;
      padding: vw(50) vw(60);
      box-sizing: border-box;
    }
    	display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
    li{
      display: inline;
      font-size: rem(14);
      line-height: 1;
      padding: 10px 0;
      @media only screen and (max-width:1080px){
        font-size: vw(20);
        padding: 0;
      }
      &:after{
        content: '>';
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }
}

#history{
  .sec01{
    background: url(../images/bg.png) repeat left top;
    .wrap{
      padding: 130px 0 120px 0;
      @media only screen and (max-width:1080px){
        padding: vw(110) vw(60) vw(200) vw(60);
        p{
          text-align: left;
          br{
            display: none;
          }
        }
      }
    }
  }
  .sec02{
    padding: 120px 0;
    @media only screen and (max-width:1080px){
      padding: vw(100) 0;
      .inner{
        padding: 0;
      }
    }
    .content{
      padding-top: 80px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      -ms-align-items: stretch;
      align-items: stretch;
      .catch{
        width: 500px;
      }
      .txt{
        width: 540px;
        padding-top: 40px;
      }
      @media only screen and (max-width:1080px){
        padding:vw(100) vw(60) vw(200) vw(60);
        h2{
          text-align: center;
        }
        .catch{
          width: 100%;
          img{
            width: 100%;
          }
        }
        .txt{
          width: 100%;
          padding-top: vw(120);
        }
      }
    }
  }
  .sec03{
    background: url(../images/bg.png) repeat left top;
    padding: 120px 0;
    .catch{
      text-align: center;
      margin-top: 70px;
    }
    @media only screen and (max-width:1080px){
        padding: 0;
      	display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-align-items: stretch;
        -ms-align-items: stretch;
        align-items: stretch;
        .catch{
          -webkit-box-ordinal-group:1;
          -ms-flex-order:1;
          -webkit-order:1;
          margin-top: 0;
          margin-bottom: vw(90);
          img{
            width: 100%;
          }
        }
        h2{
          -webkit-box-ordinal-group:2;
          -ms-flex-order:2;
          -webkit-order:2;
          text-align: center;
          width: 100%;
        }
        .txt{
          -webkit-box-ordinal-group:3;
          -ms-flex-order:3;
          -webkit-order:3;
          padding: 0 vw(60);
          padding-bottom: vw(200);
          text-align: left;
          br{
            display: none;
          }
        }
    }
  }
}

#commitment{
  .sec01{
    background: url(../images/bg.png) repeat left top;
    .wrap{
      padding: 130px 0 120px 0;
      @media only screen and (max-width:1080px){
        padding: vw(110) vw(60) vw(200) vw(60);
        p{
          text-align: left;
          br{
            display: none;
          }
        }
      }
    }
  }
  .items{
    padding-top: 120px;
    @media only screen and (max-width:1080px){
      padding-top:vw(140);
      .intro{
        padding:0 vw(60);
        p{
          text-align: left;
          br{
            display: none;
          }
        }
      }
    }
    .beans{
      position: relative;
      margin-top: 90px;
      margin-bottom: 70px;
      @media only screen and (max-width:1080px){
        margin-top: vw(80);
        margin-bottom: vw(80);
      }
      .name{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
        h2{
          text-align: center;
          font-size: rem(16);
          color: #fff;
          span{
            width: 100%;
            display: block;
            font-size: rem(36);
          }
          @media only screen and (max-width:1080px){
            font-size: vw(24);
            span{
              font-size: vw(60);
            }
          }
        }
      }
      img{
        height: 460px;
        @media only screen and (max-width:1080px){
          height:vw(480);
        }
        width: 100%;
        object-fit: cover;
      }
    }
    .title{
      font-size: rem(26);
      text-align: center;
      margin-bottom: 40px;
      font-weight: 300;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
        margin-bottom: vw(30);
      }
    }
    .txt{
      text-align: center;
      margin-bottom: 70px;
      @media only screen and (max-width:1080px){
        padding:0 vw(60);
        text-align: left;
        br{
          display: none;
        }
        margin-bottom: vw(100);
      }
    }
    .list{
      margin-bottom: 60px;
      @media only screen and (max-width:1080px){
        margin-bottom: 0;
      }
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      @media only screen and (max-width:1080px){
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
      }
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      -ms-align-items: stretch;
      align-items: stretch;
      li{
        width: 247px;
        margin-right: 30px;
        &:last-child{
          margin-right: 0;
        }
        p{
          font-size: rem(16);
          line-height: 1.6;
          &.name{
            margin-top: 30px;
            margin-bottom: 10px;
          }
        }
        @media only screen and (max-width:1080px){
          width: vw(296);
          margin-right: 0;
          margin-bottom: vw(70);
          img{
            width: 100%;
          }
          p{
            font-size: vw(26);
            span{
              display: inline-block;
              padding: vw(10) 0;
            }
            &.name{
              margin-top: vw(30);
              margin-bottom: vw(10);
            }
          }
        }
      }
    }
  }
  .suger{
    .inner{
      padding: 70px 0 140px 0;
      margin-top: 100px;
      border-top:1px solid #c0b2b2 ;
      @media only screen and (max-width:1080px){
        padding:vw(100) 0 vw(200) 0;
        width: vw(630);
        margin: 0 auto;
        margin-top:vw(80);
      }
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap:wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
      .catch{
        width: 340px;
        @media only screen and (max-width:1080px){
          width: 100%;
          img{
            width: 100%;
          }
        }
      }
      div{
        width: 700px;
        h3{
          font-size: rem(26);
          margin-bottom: 10px;
          font-weight: 300;
        }
        p{
          font-size: rem(16);
        }
        @media only screen and (max-width:1080px){
          width: 100%;
          margin-top: vw(30);
          h3{
            font-size: vw(26);
          }
          p{
            font-size: vw(26);
          }
        }
      }
    }
  }

  .sec02{
    padding-bottom: 200px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-bottom: vw(200);
    }
    .head{
      position: relative;
      margin-bottom: 70px;
      @media only screen and (max-width:1080px){
        margin-bottom: vw(80);
      }
      img{
        height: 460px;
        object-fit: cover;
        @media only screen and (max-width:1080px){
          width: 100%;
          height: auto;
          object-fit: unset;
        }
      }
      .wrap{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
        h2{
          text-align: center;
          font-size: rem(36);
          color: #fff;
          @media only screen and (max-width:1080px){
            font-size: vw(48);
          }
        }
      }
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      p{
        padding:0 vw(60);
      }
    }
    .txt{
      text-align: center;
      margin-bottom: 60px;
      @media only screen and (max-width:$SmartphoneSize + px){
        text-align: left;
        margin-bottom: vw(100);
        br{
          display: none;
        }
      }
    }
  }

}

#company{
  .wrap{
    padding: 130px 0 200px 0;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding: vw(110) 0 vw(200) 0;
    }
    .iframe_wrap{
      width: 780px;
      margin:0 auto;
      margin-bottom: 45px;
      iframe{
        width: 100%;
        height: 420px;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        width:100%;
        iframe{
          height:vw(480);
        }
      }
    }
    dl{
      width: 780px;
      margin:0 auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:100%;
        padding:0 vw(60);
        box-sizing: border-box;
      }
      div{
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-align-items: stretch;
        -ms-align-items: stretch;
        align-items: stretch;
        border-bottom: 1px solid #c0b2b2;
        font-size: rem(16);
        padding: 25px 0;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(28);
          padding:vw(50) 0;
        }
        dt{
          width: 85px;
          text-align: center;
          @media only screen and (max-width:$SmartphoneSize + px){
            width:vw(130);
          }
        }
        dd{
          width: 695px;
          padding:0 20px;
          box-sizing: border-box;
          @media only screen and (max-width:$SmartphoneSize + px){
            width:vw(500);
            padding:0 vw(30);
            span{
              display: block;
            }
          }
        }
      }
    }
  }
}

#privacy{
  h3{
    font-size: rem(20);
    margin-bottom: 30px;
    @media only screen and (max-width:1080px){
      font-size: vw(40);
      margin-bottom: vw(60);
    }
  }
  .sec01{
    @media only screen and (max-width:1080px){
      padding:0 vw(60);
    }
    .wrap{
      padding:70px 0 100px 0;
      border-bottom:1px solid #c0b2b2 ;
      width: 800px;
      margin: 0 auto;
      @media only screen and (max-width:1080px){
        width:100%;
        padding: vw(140) 0 vw(200) 0;
        box-sizing: border-box;
      }
      h2{
        text-align: center;
        font-size: rem(24);
      }
      p{
        font-size: rem(16);
      }
      dl{
        font-size: rem(16);
        margin-bottom: 70px;
        div{
          margin-bottom: 40px;
          &:last-child{
            margin-bottom: 0;
          }
          ul{
            li{
              &:before{
                content: '・';
              }
            }
          }
        }
      }
      @media only screen and (max-width:1080px){
        h2{
          font-size: vw(48);
        }
        p{
          font-size: vw(28);
        }
        dl{
          font-size: vw(28);
          margin-bottom: vw(140);
          div{
            margin-bottom: vw(80);
          }
        }
      }
    }
  }
  .sec02{
    @media only screen and (max-width:1080px){
      padding:0 vw(60);
    }
    .wrap{
      padding: 100px 0 200px 0;
      width: 800px;
      @media only screen and (max-width:1080px){
        width:100%;
        padding: vw(100) 0 vw(200) 0;
        box-sizing: border-box;
      }
      margin: 0 auto;
      p{
        font-size: rem(16);
        word-break: break-all;
        @media only screen and (max-width:1080px){
          font-size: vw(32);
        }
        a{
          text-decoration: underline;
        }
      }
    }
  }
}

#contact{
  .sec01{
    @media only screen and (max-width:1080px){
      padding:0 vw(60);
    }
    .wrap{
      padding:70px 0 100px 0;
      width: 800px;
      margin: 0 auto;
      @media only screen and (max-width:1080px){
        width:100%;
        padding: vw(140) 0 vw(200) 0;
        box-sizing: border-box;
      }
      h2{
        text-align: center;
        font-size: rem(24);
      }
      p{
        font-size: rem(16);
          a{
            text-decoration: underline;
          }
      }
      @media only screen and (max-width:1080px){
        h2{
          font-size: vw(48);
        }
        p{
          font-size: vw(28);
        }
      }
      form{
        dl{
          div{
              margin-bottom: 50px;
            	display: -webkit-flex;
              display: -ms-flex;
              display: flex;
              -ms-justify-content: flex-start;
              -webkit-justify-content: flex-start;
              justify-content: flex-start;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap:wrap;
              flex-wrap:wrap;
              -webkit-align-items: stretch;
              -ms-align-items: stretch;
              align-items: stretch;
              @media only screen and (max-width:$SmartphoneSize + px){
                margin-bottom: vw(60);
              }
            dt{
              font-size: rem(18);
              width: 180px;
              display: -webkit-flex;
              display: -ms-flex;
              display: flex;
              -webkit-align-items: center;
              -ms-align-items: center;
              align-items: center;
              @media only screen and (max-width:1080px){
                font-size: vw(24);
                width:100%;
              }
            }
            dd{
              width: calc(100% - 180px);
              input[type=text],input[type=tel],input[type=email]{
              font-family: 'Noto Serif JP', serif;
              font-size: rem(16);
              padding: 14px;
              border: none;
              display: -webkit-flex;
              display: -ms-flex;
              display: flex;
              -ms-justify-content: flex-start;
              -webkit-justify-content: flex-start;
              justify-content: flex-start;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap:wrap;
              flex-wrap:wrap;
              -webkit-align-items: center;
              -ms-align-items: center;
              align-items: center;
              background: #f5f3f6;
              width: 450px;
              box-sizing: border-box;
              }
              textarea{
                background: #f5f3f6;
                width: 100%;
                height: 190px;
                font-family: 'Noto Serif JP', serif;
                font-size: rem(14);
                padding: 14px;
                border: none;
                width: 450px;
                box-sizing: border-box;
              }
              .select{
                display: inline;
                background: #f5f3f6;
                padding: 7px 14px 14px 14px;
                &:after{
                  content: '▼';
                  font-size: 60%;
                }
                select{
                  width: 30%;
                  font-family: 'Noto Serif JP', serif;
                  font-size: rem(18);
                  border: none;
                  background: none;
                  background-image: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                }
              }
              @media only screen and (max-width:1080px){
                width:100%;
                input[type=text],input[type=tel],input[type=email]{
                  font-size: vw(24);
                  padding: vw(22);
                  box-sizing: border-box;
                  width: 100%;
                }
                .select{
                  select{
                    width: 50%;
                    font-size: vw(24);
                  }
                }
                textarea{
                  width: 100%;
                  height: vw(250);
                }
              }
            }
          }
        }
        .send{
          text-align: center;
          input[type=submit]{
            font-family: 'Noto Serif JP', serif;
            font-size: rem(18);
            background: none;
            padding: 15px 100px;
            border: 1px solid #371b4d;
            @media only screen and (max-width:$SmartphoneSize + px){
              padding:vw(35) 0;
              width:100%;
              font-size: vw(32);
            }
          }

        }
      }
    }
  }
}














































//////////////////////////////////////////////フッター
.pagetop{
  height: 70px;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-color: rgba(48,0,0,0.1);
  background: rgba(48,0,0,0.1);
  @media only screen and (max-width:1080px){
    height:vw(110);
    img{
      width: vw(48);
    }
  }
}

footer{
  background: #371b4d;
  padding: 80px 0 40px 0;
  @media only screen and (max-width:1080px){
    padding: vw(80) 0 vw(40) 0;
  }
  .inner{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
    margin-bottom: 120px;
    @media only screen and (max-width:1080px){
      margin-bottom:vw(190);
    }
    .footer_logo{
      width: 136px;
      @media only screen and (max-width:1080px){
        width:100%;
        text-align: center;
        margin-bottom: 50px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom: vw(50);
        }
      }
      img{
        width: 100%;
        @media only screen and (max-width:1080px){
          width: 260px;
        }
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(260);
        }
      }
    }
    .wrap{
      width: 885px;
      font-size: rem(18);
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(26);
      }
      color: #fff;
      @media only screen and (max-width:1080px){
        width: 100%;
      }
      ul{
        	display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          -ms-justify-content: flex-start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap:wrap;
          flex-wrap:wrap;
          -webkit-align-items: center;
          -ms-align-items: center;
          align-items: center;
          margin-bottom: 20px;
          @media only screen and (max-width:1080px){
            -ms-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
          }
          li{
            margin-right: 20px;
            a{
              color: #fff;
            }
            span{
              margin-right: 10px;
            }
          }

          @media only screen and (max-width:1080px){
            &:first-child{
              li{
                text-align: center;
                margin-right: 0;
                &:first-child{
                  width: 100%;
                  span{
                    display: block;
                  }
                }
              }
            }
          }
      }
    }
  }
  .copy{
    text-align: center;
    color: #fff;
    width: 100%;
    font-size: rem(18);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(20);
    }
  }
}

#single{
  .wrap{
    padding: 70px 0 200px 0;
    width: 780px;
    margin: 0 auto;
    @media only screen and (max-width:1080px){
      padding: 0 vw(60) vw(200) vw(60);
      width: 100%;
      box-sizing: border-box;
    }
  }
  .post{
    p{
      margin-bottom: 30px;
    }
  }
}


#news{
  .wrap{
    padding: 70px 0 200px 0;
    width: 780px;
    margin: 0 auto;
    @media only screen and (max-width:1080px){
      padding: 0 0 vw(200) 0;
      width: 100%;
    }
    ul{
      margin-bottom: 40px;
      @media only screen and (max-width:1080px){
        padding: 0 vw(60);
      }
      li{
        padding: 35px 20px;
        border-bottom: 1px solid #c0b2b2;
        @media only screen and (max-width:1080px){
          padding:vw(50) vw(20);
        }
        a{
          font-size: rem(16);
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap:wrap;
          flex-wrap:wrap;
          -webkit-align-items: stretch;
          -ms-align-items: stretch;
          align-items: stretch;
          p{
            &:first-child{
              margin-right: 30px;
            }
            @media only screen and (max-width:$SmartphoneSize + px){
              width:100%;
              &:first-child{
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}



.wp-pagenavi{
  position: relative;
  width: 100%;
  display: block;
  @media only screen and (max-width:$SmartphoneSize + px){
    width: vw(630);
    margin: 0 auto;
  }
  a{
    font-size: rem(14);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(28);
    }
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    position: absolute;
    &.nextpostslink{
      right: 0;
      &:after{
        content: '';
        width: 30px;
        height: 7px;
        background: url(../images/more.svg) no-repeat left top;
        background-size: cover;
        margin-left: 20px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(50);
          height: vw(10);
          margin-left: vw(30);
        }
      }
    }
    &.previouspostslink{
      left: 0;
      &:before{
        content: '';
        width: 30px;
        height: 7px;
        background: url(../images/more.svg) no-repeat left top;
        background-size: cover;
        margin-right: 20px;
        transform: scale(-1, 1);
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(50);
          height: vw(10);
          margin-right: vw(30);
        }
      }
    }
  }
}
